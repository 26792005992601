import { DEFAULT_REPORT_KEY, DEFAULT_TARGET_METRIC_KEY } from '../constants'

export const HOME = '/'
export const ADMIN = '/admin'
export const ANALYSIS = '/analysis'
export const CONTACTS = '/people/contacts'
export const CUSTOMERS = '/people/customers'
export const DAILY = '/daily'
export const DASHBOARD = '/dashboard'
export const DEMO = '/demo-6QHf8lWSSa088tby'
export const LOGIN = '/login'
export const PASSWORD_FORGOT = '/forgot-password'
export const PASSWORD_RESET_SENT = '/sent-reset-password'
export const PATH_ANALYSIS = ANALYSIS + '/path-analysis'
export const PAYMENTS = '/people/payments'
export const PEOPLE = '/people'
export const PROFILE = '/profile'
export const REPORTS = '/reports'
export const REPORTS_DEFAULT = REPORTS + `/${DEFAULT_REPORT_KEY}`
export const SCORECARDS = '/scorecards'
export const SETTINGS = '/settings'
export const TARGETS = '/targets'
export const TARGETS_DEFAULT = TARGETS + `/${DEFAULT_TARGET_METRIC_KEY}`
export const UPLOAD = '/upload'
export const KEAP_OAUTH = '/oauth/keap'
export const ENHANCERS_BASE = '/enhancers'
export const HYROS_ENHANCERS = ENHANCERS_BASE + '/hyros'
export const RECHARGE_ENHANCERS = ENHANCERS_BASE + '/recharge'
export const SOURCES_BASE = '/sources'
export const BASKHEALTH_SOURCES = SOURCES_BASE + '/baskhealth'
export const BUYGOODS_SOURCES = SOURCES_BASE + '/buygoods'
export const CARTPANDA_SOURCES = SOURCES_BASE + '/cartpanda'
export const CLICKBANK_SOURCES = SOURCES_BASE + '/clickbank'
export const CLICKFUNNELS_SOURCES = SOURCES_BASE + '/clickfunnels'
export const COMMITTED_SOURCES = SOURCES_BASE + '/committed'
export const CUSTOM_SOURCES = SOURCES_BASE + '/custom'
export const DIGISTORE24_SOURCES = SOURCES_BASE + '/digistore24'
export const EASYWEBINAR_SOURCES = SOURCES_BASE + '/easywebinar'
export const ELEMENTOR_SOURCES = SOURCES_BASE + '/elementor'
export const HIGHLEVEL_SOURCES = SOURCES_BASE + '/highlevel'
export const KAJABI_SOURCES = SOURCES_BASE + '/kajabi'
export const KEAP_SOURCES = SOURCES_BASE + '/keap'
export const KIT_SOURCES = SOURCES_BASE + '/kit'
export const KONNEKTIVE_SOURCES = SOURCES_BASE + '/konnektive'
export const MAROPOST_SOURCES = SOURCES_BASE + '/maropost'
export const ONTRAPORT_SOURCES = SOURCES_BASE + '/ontraport'
export const PAYPAL_SOURCES = SOURCES_BASE + '/paypal'
export const SAMCART_SOURCES = SOURCES_BASE + '/samcart'
export const SHOPIFY_SOURCES = SOURCES_BASE + '/shopify'
export const SHOPIFY_APP_INSTALL = '/shopify/install'
export const SHOPIFY_OAUTH = '/oauth/shopify'
export const STICKY_SOURCES = SOURCES_BASE + '/sticky'
export const STRIPE_SOURCES = SOURCES_BASE + '/stripe'
export const STRIPE_OAUTH = '/integrations/stripe/oauth'
export const ULTRACART_SOURCES = SOURCES_BASE + '/ultracart'
export const WOOCOMMERCE_SOURCES = SOURCES_BASE + '/woocommerce'