// Mapping of report identifiers to their definitions

import METRIC_ITEMS_MAP from './MetricItems'

export const CORE_REPORTS = [
  {
    'kind': 'core',
    'key': 'sales_overview',
    'name': 'Sales Overview',
    'description': 'Core sales metrics over time',
    'xKey': 'date_time',
    'xKeyAsRange': null,
    'allowSegmentQuickAdd': true,
    'allowCohorts': false,
    'splitSegmentFrontBack': false,
    'emptyDataRule': 'zero',
    'dateRangeType': 'dynamic',
    'dateRangeDynamicDays': 90,
    'dateRangeFixedStartDate': null,
    'dateRangeFixedEndDate': null,
    'resolution': 1,
    'segments': [],
    'config': {
      'includeCosts': true,
      'includeCommission': false,
      'kind': 'all',
    },
    'configItems': {
      'includeCosts': {
        'show': false,
        'name': 'Include Costs',
        'items': [
          {
            'value': true,
            'name': 'Yes'
          },
          {
            'value': false,
            'name': 'No'
          },
        ],
      },
      'includeCommission': {
        'show': true,
        'name': 'Affiliate Commission',
        'items': [
          {
            'value': false,
            'name': 'Subtract from revenue'
          },
          {
            'value': true,
            'name': 'Add to revenue'
          },
        ],
      },
      'kind': {
        'show': true,
        'name': 'Sale Kind',
        'items': [
          {
            'value': 'all',
            'name': 'All'
          },
          {
            'value': 'frontend',
            'name': 'Frontend'
          },
          {
            'value': 'backend',
            'name': 'Backend'
          },
        ],
      },
    },
    'metrics': {
      'defaultKey': 'revenue_net',
      'items': [
        {
          ...METRIC_ITEMS_MAP['revenue_net'],
        },
        {
          ...METRIC_ITEMS_MAP['revenue_gross'],
          'secondary': {
            ...METRIC_ITEMS_MAP['revenue_refunds'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['revenue_orders'],
          'secondary': {
            ...METRIC_ITEMS_MAP['orders'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['revenue_rebills'],
          'secondary': {
            ...METRIC_ITEMS_MAP['rebills'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['revenue_refunds'],
          'secondary': {
            ...METRIC_ITEMS_MAP['refunds'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['aov'],
          'secondary': {
            ...METRIC_ITEMS_MAP['orders'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['orders'],
        },
        {
          ...METRIC_ITEMS_MAP['customers'],
        },
        {
          ...METRIC_ITEMS_MAP['gross_profit'],
        },
      ],
    },
    'milestoneDays': [],
    'chart': {
      'allowSecondaryChart': true,
      'allowLineDots': true,
      'allowUseStack100': true,
      'cumulativeDefault': false,
      'hideYValuesAfterToday': false,
    },
    'table': {
      'defaultTab': 'summary',
      'summary': {
        'show': true,
        'columns': {
          'default': {
            'name': 'Overview',
            'description': 'Overview of Key Metrics',
            'orderBy': 'customers',
            'order': 'desc',
            'items': [
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['customers'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['orders'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['aov'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_net'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_gross'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_orders'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_rebills'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_refunds'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['rebills'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['refunds'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['gross_profit'],
              },
            ],
          },
        },
      },
      'chart': {
        'show': true,
      },
      'milestones': {
        'show': false,
        'days': [],
      },
    },
  },

  {
    'kind': 'core',
    'key': 'revenue',
    'name': 'Revenue',
    'description': 'Revenue over time',
    'xKey': 'date_time',
    'xKeyAsRange': null,
    'allowSegmentQuickAdd': true,
    'allowCohorts': false,
    'splitSegmentFrontBack': false,
    'emptyDataRule': 'zero',
    'dateRangeType': 'dynamic',
    'dateRangeDynamicDays': 90,
    'dateRangeFixedStartDate': null,
    'dateRangeFixedEndDate': null,
    'resolution': 1,
    'segments': [],
    'config': {
      'includeCosts': true,
      'includeCommission': false,
    },
    'configItems': {
      'includeCosts': {
        'show': false,
        'name': 'Include Costs',
        'items': [
          {
            'value': true,
            'name': 'Yes'
          },
          {
            'value': false,
            'name': 'No'
          },
        ],
      },
      'includeCommission': {
        'show': true,
        'name': 'Affiliate Commission',
        'items': [
          {
            'value': false,
            'name': 'Subtract from revenue'
          },
          {
            'value': true,
            'name': 'Add to revenue'
          },
        ],
      },
    },
    'metrics': {
      'defaultKey': 'revenue_net',
      'items': [
        {
          ...METRIC_ITEMS_MAP['revenue_net'],
        },
        {
          ...METRIC_ITEMS_MAP['revenue_net_frontend'],
          'secondary': {
            ...METRIC_ITEMS_MAP['revenue_net_backend'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['revenue_net_backend'],
          'secondary': {
            ...METRIC_ITEMS_MAP['revenue_net_frontend'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['revenue_gross'],
          'secondary': {
            ...METRIC_ITEMS_MAP['revenue_refunds'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['revenue_orders'],
        },
        {
          ...METRIC_ITEMS_MAP['revenue_rebills'],
        },
        {
          ...METRIC_ITEMS_MAP['revenue_refunds'],
        },
      ],
    },
    'milestoneDays': [],
    'chart': {
      'allowSecondaryChart': true,
      'allowLineDots': true,
      'allowUseStack100': true,
      'cumulativeDefault': false,
      'hideYValuesAfterToday': false,
    },
    'table': {
      'defaultTab': 'summary',
      'summary': {
        'show': true,
        'columns': {
          'default': {
            'name': 'Overview',
            'description': 'Overview of Key Metrics',
            'orderBy': 'revenue_net',
            'order': 'desc',
            'items': [
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_net'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_net_frontend'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_net_backend'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_gross'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_orders'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_rebills'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_refunds'],
              },
            ],
          },
        },
      },
      'chart': {
        'show': true,
      },
      'milestones': {
        'show': false,
        'days': [],
      },
    },
  },

  {
    'kind': 'core',
    'key': 'aov',
    'name': 'Average Order Value',
    'description': 'Order revenue divided by number of orders, with options to see total, frontend, and backend AOVs.',
    'xKey': 'date_time',
    'xKeyAsRange': null,
    'allowSegmentQuickAdd': true,
    'allowCohorts': false,
    'splitSegmentFrontBack': false,
    'emptyDataRule': 'empty',
    'dateRangeType': 'dynamic',
    'dateRangeDynamicDays': 90,
    'dateRangeFixedStartDate': null,
    'dateRangeFixedEndDate': null,
    'resolution': 1,
    'segments': [],
    'config': {
      'includeCosts': true,
      'includeCommission': false,
    },
    'configItems': {
      'includeCosts': {
        'show': false,
        'name': 'Include Costs',
        'items': [
          {
            'value': true,
            'name': 'Yes'
          },
          {
            'value': false,
            'name': 'No'
          },
        ],
      },
      'includeCommission': {
        'show': true,
        'name': 'Affiliate Commission',
        'items': [
          {
            'value': false,
            'name': 'Subtract from revenue'
          },
          {
            'value': true,
            'name': 'Add to revenue'
          },
        ],
      },
    },
    'metrics': {
      'defaultKey': 'aov',
      'items': [
        {
          ...METRIC_ITEMS_MAP['aov'],
          'secondary': {
            ...METRIC_ITEMS_MAP['orders'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['aov_frontend'],
          'secondary': {
            ...METRIC_ITEMS_MAP['orders_frontend'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['aov_backend'],
          'secondary': {
            ...METRIC_ITEMS_MAP['orders_backend'],
          },
        },
      ],
    },
    'milestoneDays': [],
    'chart': {
      'allowSecondaryChart': true,
      'allowLineDots': true,
      'allowUseStack100': true,
      'cumulativeDefault': false,
      'hideYValuesAfterToday': false,
    },
    'table': {
      'defaultTab': 'summary',
      'summary': {
        'show': true,
        'columns': {
          'default': {
            'name': 'Overview',
            'description': 'Overview of Key Metrics',
            'orderBy': 'orders',
            'order': 'desc',
            'items': [
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['orders'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['orders_frontend'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['orders_backend'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['aov'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['aov_frontend'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['aov_backend'],
              },
            ],
          }
        },
      },
      'chart': {
        'show': true,
      },
      'milestones': {
        'show': false,
        'days': [],
      },
    },
  },

  {
    'kind': 'core',
    'key': 'aovc',
    'name': 'AOV Contributions',
    'description': 'Contributions of each product to order value metrics. It is recommended that you use this report with filters or segments that include a funnel, and apply a product breakdown to see how much each product contributes to AOV. Note that the abbreviation "GP" stands for Gross Profit.',
    'xKey': 'date_time',
    'xKeyAsRange': null,
    'allowSegmentQuickAdd': true,
    'allowCohorts': false,
    'splitSegmentFrontBack': false,
    'emptyDataRule': 'empty',
    'dateRangeType': 'dynamic',
    'dateRangeDynamicDays': 90,
    'dateRangeFixedStartDate': null,
    'dateRangeFixedEndDate': null,
    'resolution': 1,
    'segments': [],
    'config': {
      'includeCosts': true,
      'includeCommission': false,
      'kind': 'all',
    },
    'configItems': {
      'includeCosts': {
        'show': false,
        'name': 'Include Costs',
        'items': [
          {
            'value': true,
            'name': 'Yes'
          },
          {
            'value': false,
            'name': 'No'
          },
        ],
      },
      'includeCommission': {
        'show': true,
        'name': 'Affiliate Commission',
        'items': [
          {
            'value': false,
            'name': 'Subtract from revenue'
          },
          {
            'value': true,
            'name': 'Add to revenue'
          },
        ],
      },
      'kind': {
        'show': true,
        'name': 'Order Kind',
        'items': [
          {
            'value': 'all',
            'name': 'All'
          },
          {
            'value': 'frontend',
            'name': 'Frontend'
          },
          {
            'value': 'backend',
            'name': 'Backend'
          },
        ],
      },
    },
    'metrics': {
      'defaultKey': 'aovc',
      'items': [
        {
          ...METRIC_ITEMS_MAP['aovc'],
          'secondary': {
            ...METRIC_ITEMS_MAP['orders'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['aogpc'],
          'secondary': {
            ...METRIC_ITEMS_MAP['orders'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['costc'],
          'secondary': {
            ...METRIC_ITEMS_MAP['orders'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['order_rate'],
        },
        {
          ...METRIC_ITEMS_MAP['revenue_orders'],
          'secondary': {
            ...METRIC_ITEMS_MAP['orders'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['aovc_rate'],
        },
        {
          ...METRIC_ITEMS_MAP['aogpc_rate'],
        },
        {
          ...METRIC_ITEMS_MAP['costc_rate'],
        },
        {
          ...METRIC_ITEMS_MAP['cost_orders'],
          'secondary': {
            ...METRIC_ITEMS_MAP['orders'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['gross_profit_orders'],
          'secondary': {
            ...METRIC_ITEMS_MAP['orders'],
          },
        },
      ],
    },
    'milestoneDays': [],
    'chart': {
      'allowSecondaryChart': true,
      'allowLineDots': true,
      'allowUseStack100': true,
      'cumulativeDefault': false,
      'hideYValuesAfterToday': false,
    },
    'table': {
      'defaultTab': 'summary',
      'summary': {
        'show': true,
        'columns': {
          'default': {
            'name': 'Overview',
            'description': 'Contributions overview',
            'orderBy': 'orders',
            'order': 'desc',
            'items': [
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['orders'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['order_rate'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['aovc'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['aogpc'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['costc'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_orders'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['gross_profit_orders'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['cost_orders'],
              },
            ],
          },
          'rates': {
            'name': 'Percentages',
            'description': 'Percantage contributions to each key metric',
            'orderBy': 'orders',
            'order': 'desc',
            'items': [
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['orders'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['order_rate'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['aovc_rate'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['aogpc_rate'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['costc_rate'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_orders'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['gross_profit_orders'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['cost_orders'],
              },
            ],
          }
        }
      },
      'chart': {
        'show': true,
      },
      'milestones': {
        'show': false,
        'days': [],
      }
    },
  },

  {
    'kind': 'core',
    'key': 'customers',
    'name': 'Customers',
    'description': 'Customer counts, broken into new and returning customers. "Rates" refer to the number of new or returning customers divided by the total number of customers. Note that it is possible for "New Customer Rate" + "Returning Customer Rate" to be greater than 100% because an invidiual customer can be both new and returning if they make multiple purchases in a period.',
    'xKey': 'date_time',
    'xKeyAsRange': null,
    'allowSegmentQuickAdd': true,
    'allowCohorts': false,
    'splitSegmentFrontBack': false,
    'emptyDataRule': 'zero',
    'dateRangeType': 'dynamic',
    'dateRangeDynamicDays': 90,
    'dateRangeFixedStartDate': null,
    'dateRangeFixedEndDate': null,
    'resolution': 1,
    'segments': [],
    'config': {
      'includeCommission': false,
    },
    'configItems': {
      'includeCommission': {
        'show': true,
        'name': 'Affiliate Commission',
        'items': [
          {
            'value': false,
            'name': 'Subtract from revenue'
          },
          {
            'value': true,
            'name': 'Add to revenue'
          },
        ],
      },
    },
    'metrics': {
      'defaultKey': 'customers',
      'items': [
        {
          ...METRIC_ITEMS_MAP['customers'],
          name: 'Total Customers',
        },
        {
          ...METRIC_ITEMS_MAP['customers_frontend'],
          'secondary': {
            ...METRIC_ITEMS_MAP['customers_backend'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['customers_backend'],
          'secondary': {
            ...METRIC_ITEMS_MAP['customers_frontend'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['customers_frontend_rate'],
          'secondary': {
            ...METRIC_ITEMS_MAP['customers_frontend'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['customers_backend_rate'],
          'secondary': {
            ...METRIC_ITEMS_MAP['customers_backend'],
          },
        },
      ],
    },
    'milestoneDays': [],
    'chart': {
      'allowSecondaryChart': true,
      'allowLineDots': true,
      'allowUseStack100': true,
      'cumulativeDefault': false,
      'hideYValuesAfterToday': false,
    },
    'table': {
      'defaultTab': 'summary',
      'summary': {
        'show': true,
        'columns': {
          'default': {
            'name': 'Overview',
            'description': 'Overview of Key Metrics',
            'orderBy': 'customers',
            'order': 'desc',
            'items': [
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['customers'],
                name: 'Total Customers',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['customers_frontend'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['customers_backend'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['customers_frontend_rate'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['customers_backend_rate'],
              },
            ]
          }
        },
      },
      'chart': {
        'show': true,
      },
      'milestones': {
        'show': false,
        'days': [],
      }
    },
  },

  {
    'kind': 'core',
    'key': 'leads',
    'name': 'Leads',
    'description': 'Lead counts over time.',
    'xKey': 'date_time',
    'xKeyAsRange': null,
    'allowSegmentQuickAdd': true,
    'allowCohorts': false,
    'splitSegmentFrontBack': false,
    'emptyDataRule': 'zero',
    'dateRangeType': 'dynamic',
    'dateRangeDynamicDays': 90,
    'dateRangeFixedStartDate': null,
    'dateRangeFixedEndDate': null,
    'resolution': 1,
    'segments': [],
    'config': {
      'attribution': 'lead',
    },
    'configItems': {
      'attribution': {
        'show': false,
        'name': 'Attribution',
        'items': [
          {
            'value': 'lead',
            'name': 'Lead'
          },
        ],
      },
    },
    'metrics': {
      'defaultKey': 'leads',
      'items': [
        {
          ...METRIC_ITEMS_MAP['leads'],
          name: 'New Leads',
        },
      ],
    },
    'milestoneDays': [],
    'chart': {
      'allowSecondaryChart': true,
      'allowLineDots': true,
      'allowUseStack100': true,
      'cumulativeDefault': false,
      'hideYValuesAfterToday': false,
    },
    'table': {
      'defaultTab': 'summary',
      'summary': {
        'show': true,
        'columns': {
          'default': {
            'name': 'Overview',
            'description': 'Overview of Key Metrics',
            'orderBy': 'leads',
            'order': 'desc',
            'items': [
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['leads'],
                name: 'New Leads',
              },
            ]
          }
        },
      },
      'chart': {
        'show': true,
      },
      'milestones': {
        'show': false,
        'days': [],
      }
    },
  },

  {
    'kind': 'core',
    'key': 'refunds',
    'name': 'Refund Rate',
    'description': 'Refund metrics over time. Note that "Refund Rate" is Refunds divided by Gross Revenue, meaning that it is based on retained revenue as opposed to counting the number of refunds. This is a more reliable metric as there is often disagreement about how to count the number of refunds. Ultimately, the dollars are what matters. Note that the refund rate is not cohort-based, which means that the refund rate is calculated based on the total revenue and total refunds for the period, and does not taken into account when the original sale happened before it was refunded.',
    'xKey': 'date_time',
    'xKeyAsRange': null,
    'allowSegmentQuickAdd': true,
    'allowCohorts': false,
    'splitSegmentFrontBack': false,
    'emptyDataRule': 'zero',
    'dateRangeType': 'dynamic',
    'dateRangeDynamicDays': 90,
    'dateRangeFixedStartDate': null,
    'dateRangeFixedEndDate': null,
    'resolution': 1,
    'segments': [],
    'config': {
      'includeCosts': true,
      'includeCommission': false,
    },
    'configItems': {
      'includeCosts': {
        'show': false,
        'name': 'Include Costs',
        'items': [
          {
            'value': true,
            'name': 'Yes'
          },
          {
            'value': false,
            'name': 'No'
          },
        ],
      },
      'includeCommission': {
        'show': true,
        'name': 'Affiliate Commission',
        'items': [
          {
            'value': false,
            'name': 'Subtract from revenue'
          },
          {
            'value': true,
            'name': 'Add to revenue'
          },
        ],
      },
    },
    'metrics': {
      'defaultKey': 'revenue_refunds_rate',
      'items': [
        {
          ...METRIC_ITEMS_MAP['revenue_refunds_rate'],
          'secondary': {
            ...METRIC_ITEMS_MAP['refunds'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['revenue_refunds'],
          'secondary': {
            ...METRIC_ITEMS_MAP['refunds'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['revenue_gross'],
          'secondary': {
            ...METRIC_ITEMS_MAP['revenue_refunds'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['revenue_net'],
        },
      ],
    },
    'milestoneDays': [],
    'chart': {
      'allowSecondaryChart': true,
      'allowLineDots': true,
      'allowUseStack100': true,
      'cumulativeDefault': false,
      'hideYValuesAfterToday': false,
    },
    'table': {
      'defaultTab': 'summary',
      'summary': {
        'show': true,
        'columns': {
          'default': {
            'name': 'Overview',
            'description': 'Overview of Key Metrics',
            'orderBy': 'refunds',
            'order': 'desc',
            'items': [
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['refunds'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_refunds_rate'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_refunds'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_gross'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_net'],
              },
            ],
          },
        },
      },
      'chart': {
        'show': true,
      },
      'milestones': {
        'show': false,
        'days': [],
      },
    },
  },

  {
    'kind': 'core',
    'key': 'ltv',
    'name': 'Lifetime Value',
    'description': 'Lifetime value day by day',
    'xKey': 'day',
    'xKeyAsRange': null,
    'allowSegmentQuickAdd': true,
    'allowCohorts': true,
    'splitSegmentFrontBack': false,
    'emptyDataRule': 'previous',
    'dateRangeType': 'dynamic',
    'dateRangeDynamicDays': 90,
    'dateRangeFixedStartDate': null,
    'dateRangeFixedEndDate': null,
    'resolution': 2,
    'segments': [],
    'config': {
      'attribution': 'customer',
      'frontendEnforcement': 'absolute',
      'includeCosts': true,
      'includeCommission': false,
      'extendBackend': true,
    },
    'configItems': {
      'attribution': {
        'show': true,
        'name': 'Attribution',
        'items': [
          {
            'value': 'customer',
            'name': 'Customer'
          },
          {
            'value': 'lead',
            'name': 'Lead'
          },
        ],
      },
      'frontendEnforcement': {
        'show': false,
        'name': 'Analyze LTV for',
        'items': [
          {
            'value': 'absolute',
            'name': 'New customers'
          },
          {
            'value': 'relative',
            'name': 'All customers'
          },
        ],
      },
      'includeCosts': {
        'show': false,
        'name': 'Include Costs',
        'items': [
          {
            'value': true,
            'name': 'Yes'
          },
          {
            'value': false,
            'name': 'No'
          },
        ],
      },
      'includeCommission': {
        'show': true,
        'name': 'Affiliate Commission',
        'items': [
          {
            'value': false,
            'name': 'Subtract from revenue'
          },
          {
            'value': true,
            'name': 'Add to revenue'
          },
        ],
      },
      'extendBackend': {
        'show': false,
        'name': 'Extend Backend',
        'items': [
          {
            'value': true,
            'name': 'To today'
          },
          {
            'value': false,
            'name': 'To end date'
          },
        ],
      }
    },
    'metrics': {
      'defaultKey': 'ltv',
      'items': [
        {
          ...METRIC_ITEMS_MAP['ltv'],
          'secondary': {
            ...METRIC_ITEMS_MAP['confidence'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['ltgpp'],
          'secondary': {
            ...METRIC_ITEMS_MAP['confidence'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['ltr'],
        },
        {
          ...METRIC_ITEMS_MAP['ltgp'],
        },
        {
          ...METRIC_ITEMS_MAP['count'],
          'secondary': {
            ...METRIC_ITEMS_MAP['confidence'],
          },
        },
      ],
    },
    'milestoneDays': [0, 7, 14, 30, 60, 90, 120, 150, 180, 270, 365],
    'chart': {
      'allowSecondaryChart': true,
      'allowLineDots': true,
      'allowUseStack100': true,
      'cumulativeDefault': false,
      'hideYValuesAfterToday': false,
    },
    'table': {
      'defaultTab': 'summary',
      'summary': {
        'show': true,
        'columns': {
          'default': {
            'name': 'LTV Overview',
            'description': 'Overview of LTV Metrics',
            'orderBy': 'count-0',
            'order': 'desc',
            'items': [
              {
                'type': 'milestones',
                'metricKey': 'count',
                'name': 'People',
                'concatDay': false,
                'milestoneDays': [0],
              },
              {
                'type': 'milestones',
                'metricKey': 'ltv',
                'name': 'LTV',
                'concatDay': true,
                'milestoneDays': [0, 30, 60, 90],
              },
              {
                'type': 'milestones',
                'metricKey': 'ltv',
                'name': 'LTV',
                'concatDay': true,
                'milestoneDays': ['max'],
              },
              {
                'type': 'milestones',
                'metricKey': 'ltgpp',
                'name': 'LTGPP',
                'concatDay': true,
                'milestoneDays': ['max'],
              },
              {
                'type': 'milestones',
                'metricKey': 'ltr',
                'name': 'LTR',
                'concatDay': false,
                'milestoneDays': ['max'],
              },
              {
                'type': 'milestones',
                'metricKey': 'ltgp',
                'name': 'LTGP',
                'concatDay': false,
                'milestoneDays': ['max'],
              },
            ]
          },
          'ltgpp': {
            'name': 'LTGPP Overview',
            'description': 'Overview of LTGPP Metrics',
            'orderBy': 'count-0',
            'order': 'desc',
            'items': [
              {
                'type': 'milestones',
                'metricKey': 'count',
                'name': 'Customers',
                'concatDay': false,
                'milestoneDays': [0],
              },
              {
                'type': 'milestones',
                'metricKey': 'ltgpp',
                'name': 'LTGPP',
                'concatDay': true,
                'milestoneDays': [0, 30, 60, 90],
              },
              {
                'type': 'milestones',
                'metricKey': 'ltgpp',
                'name': 'LTGPP',
                'concatDay': true,
                'milestoneDays': ['max'],
              },
              {
                'type': 'milestones',
                'metricKey': 'ltgp',
                'name': 'LTGP',
                'concatDay': false,
                'milestoneDays': ['max'],
              },
              {
                'type': 'milestones',
                'metricKey': 'ltr',
                'name': 'LTR',
                'concatDay': false,
                'milestoneDays': ['max'],
              },
            ]
          }
        }
      },
      'chart': {
        'show': true,
      },
      'milestones': {
        'show': true,
      }
    },
  },

  {
    'kind': 'core',
    'key': 'returning_customer_behavior',
    'name': 'Returning Customer Behavior',
    'description': 'In-depth analysis of returning customer behavior. You can analyze different segments or apply a frontend breakdown to compare returning customer behavior across different groups of new customers. Or you can apply a backend breakdown to see what returning customers are buying. A common pattern would be to pick a frontend customer segment and apply a backend breakdown by product to see what they buy and when after their initial purchase.',
    'xKey': 'day',
    'xKeyAsRange': 'resolution',
    'allowSegmentQuickAdd': true,
    'allowCohorts': true,
    'splitSegmentFrontBack': true,
    'emptyDataRule': 'zero',
    'dateRangeType': 'dynamic',
    'dateRangeDynamicDays': 90,
    'dateRangeFixedStartDate': null,
    'dateRangeFixedEndDate': null,
    'resolution': 2,
    'segments': [],
    'config': {
      'attribution': 'customer',
      'frontendEnforcement': 'absolute',
      'includeCosts': true,
      'includeCommission': false,
      'extendBackend': true,
    },
    'configItems': {
      'attribution': {
        'override': true,
        'show': false,
        'name': 'Attribution',
        'items': [
          {
            'value': 'customer',
            'name': 'Customer'
          },
        ],
      },
      'frontendEnforcement': {
        'show': false,
        'name': 'Analyze LTV for',
        'items': [
          {
            'value': 'absolute',
            'name': 'New customers'
          },
          {
            'value': 'relative',
            'name': 'All customers'
          },
        ],
      },
      'includeCosts': {
        'show': false,
        'name': 'Include Costs',
        'items': [
          {
            'value': true,
            'name': 'Yes'
          },
          {
            'value': false,
            'name': 'No'
          },
        ],
      },
      'includeCommission': {
        'show': true,
        'name': 'Affiliate Commission',
        'items': [
          {
            'value': false,
            'name': 'Subtract from revenue'
          },
          {
            'value': true,
            'name': 'Add to revenue'
          },
        ],
      },
      'extendBackend': {
        'show': false,
        'name': 'Extend Backend',
        'items': [
          {
            'value': true,
            'name': 'To today'
          },
          {
            'value': false,
            'name': 'To end date'
          },
        ],
      }
    },
    'metrics': {
      'defaultKey': 'customers_backend_rate_j',
      'items': [
        {
          ...METRIC_ITEMS_MAP['customers_backend_rate_j'],
          'secondary': {
            ...METRIC_ITEMS_MAP['customers'],
            'name': 'Returning Customers',
            'cumulativeName': 'Returning Customers, non-unique',
            'allowCumulative': true,
          },
        },
        {
          ...METRIC_ITEMS_MAP['revenue_backend_rate_j'],
          'secondary': {
            ...METRIC_ITEMS_MAP['revenue_net'],
            'name': 'Net Backend Revenue',
          },
        },
        {
          ...METRIC_ITEMS_MAP['customers'],
          'name': 'Returning Customers',
          'cumulativeName': 'Returning Customers, non-unique',
          'allowCumulative': true,
        },
        {
          ...METRIC_ITEMS_MAP['revenue_net'],
        },
        {
          ...METRIC_ITEMS_MAP['revenue_gross'],
        },
        {
          ...METRIC_ITEMS_MAP['revenue_orders'],
          'secondary': {
            ...METRIC_ITEMS_MAP['orders'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['revenue_rebills'],
          'secondary': {
            ...METRIC_ITEMS_MAP['rebills'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['revenue_refunds'],
          'secondary': {
            ...METRIC_ITEMS_MAP['refunds'],
          },
        },
      ]
    },
    'milestoneDays': [],
    'chart': {
      'allowSecondaryChart': true,
      'allowLineDots': true,
      'allowUseStack100': true,
      'cumulativeDefault': true,
      'hideYValuesAfterToday': false,
    },
    'table': {
      'defaultTab': 'summary',
      'summary': {
        'show': true,
        'columns': {
          'default': {
            'name': 'Overview',
            'description': 'Overview',
            'orderBy': 'customers_backend_rate_j',
            'order': 'desc',
            'items': [
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['customers_backend_rate_j'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['count'],
                'name': 'New Customers',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['customers'],
                'name': 'Returning Customers',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_backend_rate_j'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_net_frontend'],
                'name': 'Frontend Revenue',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_net'],
                'name': 'Net Backend Revenue',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_gross'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_orders'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_rebills'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_refunds'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['orders'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['rebills'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['refunds'],
              },
            ],
          },
        }
      },
      'chart': {
        'show': true,
      },
      'milestones': {
        'show': false,
        'days': [],
      }
    },
  },

  {
    'kind': 'core',
    'key': 'returning_lead_behavior',
    'name': 'Returning Lead Behavior',
    'description': 'In-depth analysis of returning lead behavior. You can analyze different segments or apply a frontend breakdown to compare returning lead behavior across different groups of new leads. Or you can apply a backend breakdown to see what returning leads are buying. A common pattern would be to pick a frontend segment of leads and apply a backend breakdown by product to see what they buy and when after becoming a lead.',
    'xKey': 'day',
    'xKeyAsRange': 'resolution',
    'allowSegmentQuickAdd': true,
    'allowCohorts': true,
    'splitSegmentFrontBack': true,
    'emptyDataRule': 'zero',
    'dateRangeType': 'dynamic',
    'dateRangeDynamicDays': 90,
    'dateRangeFixedStartDate': null,
    'dateRangeFixedEndDate': null,
    'resolution': 2,
    'segments': [],
    'config': {
      'attribution': 'lead',
      'includeCosts': true,
      'includeCommission': false,
      'extendBackend': true,
    },
    'configItems': {
      'attribution': {
        'override': true,
        'show': false,
        'name': 'Attribution',
        'items': [
          {
            'value': 'lead',
            'name': 'Lead'
          },
        ],
      },
      'includeCosts': {
        'show': false,
        'name': 'Include Costs',
        'items': [
          {
            'value': true,
            'name': 'Yes'
          },
          {
            'value': false,
            'name': 'No'
          },
        ],
      },
      'includeCommission': {
        'show': true,
        'name': 'Affiliate Commission',
        'items': [
          {
            'value': false,
            'name': 'Subtract from revenue'
          },
          {
            'value': true,
            'name': 'Add to revenue'
          },
        ],
      },
      'extendBackend': {
        'show': false,
        'name': 'Extend Backend',
        'items': [
          {
            'value': true,
            'name': 'To today'
          },
          {
            'value': false,
            'name': 'To end date'
          },
        ],
      },
    },
    'metrics': {
      'defaultKey': 'customers_backend_rate_j',
      'items': [
        {
          ...METRIC_ITEMS_MAP['customers_backend_rate_j'],
          'name': 'Lead to Customer Rate (Journey)',
          'cumulativeName': 'Lead to Customer Rate (Journey), non-unique',
          'secondary': {
            ...METRIC_ITEMS_MAP['customers'],
            'name': 'Customers',
            'cumulativeName': 'Customers, non-unique',
            'allowCumulative': true,
          },
        },
        {
          ...METRIC_ITEMS_MAP['customers'],
          'name': 'Customers',
          'cumulativeName': 'Customers, non-unique',
          'allowCumulative': true,
        },
        {
          ...METRIC_ITEMS_MAP['revenue_net'],
        },
        {
          ...METRIC_ITEMS_MAP['revenue_gross'],
        },
        {
          ...METRIC_ITEMS_MAP['revenue_orders'],
          'secondary': {
            ...METRIC_ITEMS_MAP['orders'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['revenue_rebills'],
          'secondary': {
            ...METRIC_ITEMS_MAP['rebills'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['revenue_refunds'],
          'secondary': {
            ...METRIC_ITEMS_MAP['refunds'],
          },
        },
      ]
    },
    'milestoneDays': [],
    'chart': {
      'allowSecondaryChart': true,
      'allowLineDots': true,
      'allowUseStack100': true,
      'cumulativeDefault': true,
      'hideYValuesAfterToday': false,
    },
    'table': {
      'defaultTab': 'summary',
      'summary': {
        'show': true,
        'columns': {
          'default': {
            'name': 'Overview',
            'description': 'Overview',
            'orderBy': 'customers_backend_rate_j',
            'order': 'desc',
            'items': [
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['customers_backend_rate_j'],
                'name': 'Lead to Customer Rate (Journey)',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['count'],
                'name': 'New Leads',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['customers'],
                'name': 'Customers',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_net'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_gross'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_orders'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_rebills'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_refunds'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['orders'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['rebills'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['refunds'],
              },
            ],
          },
        }
      },
      'chart': {
        'show': true,
      },
      'milestones': {
        'show': false,
        'days': [],
      }
    },
  },

  {
    'kind': 'core',
    'key': 'executive_summary',
    'name': 'Executive Summary',
    'description': 'Key metrics for executive-level overview of the business',
    'xKey': 'date_time',
    'xKeyAsRange': null,
    'allowSegmentQuickAdd': true,
    'allowCohorts': false,
    'splitSegmentFrontBack': false,
    'emptyDataRule': 'zero',
    'dateRangeType': 'dynamic',
    'dateRangeDynamicDays': 90,
    'dateRangeFixedStartDate': null,
    'dateRangeFixedEndDate': null,
    'resolution': 1,
    'segments': [],
    'config': {
      'includeCosts': true,
      'includeCommission': false,
      'kind': 'all',
    },
    'configItems': {
      'includeCosts': {
        'show': false,
        'name': 'Include Costs',
        'items': [
          {
            'value': true,
            'name': 'Yes'
          },
          {
            'value': false,
            'name': 'No'
          },
        ],
      },
      'includeCommission': {
        'show': true,
        'name': 'Affiliate Commission',
        'items': [
          {
            'value': false,
            'name': 'Subtract from revenue'
          },
          {
            'value': true,
            'name': 'Add to revenue'
          },
        ],
      },
      'kind': {
        'show': false,
        'name': 'Sale Kind',
        'items': [
          {
            'value': 'all',
            'name': 'All'
          },
          {
            'value': 'frontend',
            'name': 'Frontend'
          },
          {
            'value': 'backend',
            'name': 'Backend'
          },
        ],
      },
    },
    'metrics': {
      'defaultKey': 'revenue_gross',
      'items': [
        {
          ...METRIC_ITEMS_MAP['revenue_gross'],
        },
        {
          ...METRIC_ITEMS_MAP['gross_profit'],
        },
        {
          ...METRIC_ITEMS_MAP['revenue_refunds'],
          'secondary': {
            ...METRIC_ITEMS_MAP['refunds'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['aov'],
          'secondary': {
            ...METRIC_ITEMS_MAP['orders'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['aogp'],
          'secondary': {
            ...METRIC_ITEMS_MAP['orders'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['orders'],
        },
        {
          ...METRIC_ITEMS_MAP['customers'],
        },
        {
          ...METRIC_ITEMS_MAP['refunds'],
        },
      ],
    },
    'milestoneDays': [],
    'chart': {
      'allowSecondaryChart': true,
      'allowLineDots': true,
      'allowUseStack100': true,
      'cumulativeDefault': false,
      'hideYValuesAfterToday': false,
    },
    'table': {
      'defaultTab': 'summary',
      'summary': {
        'show': true,
        'columns': {
          'default': {
            'name': 'Overview',
            'description': 'Overview of Key Metrics',
            'orderBy': 'revenue_gross',
            'order': 'desc',
            'items': [
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_gross'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['gross_profit'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['aov'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['aogp'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['orders'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['customers'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_refunds'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['refunds'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_gross_frontend'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['gross_profit_frontend'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['aov_frontend'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['aogp_frontend'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['orders_frontend'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['revenue_gross_backend'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['gross_profit_backend'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['aov_backend'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['aogp_backend'],
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['orders_backend'],
              },
            ],
          },
        },
      },
      'chart': {
        'show': true,
      },
      'milestones': {
        'show': false,
        'days': [],
      },
    },
  },

  {
    'kind': 'core',
    'key': 'ltv2',
    'name': 'Lifetime Value v2 (Alpha)',
    'description': 'Comphrensive lifetime value analysis that shows lifetime value at every day of the customer journey, with the ability to filter both frontend and backend sales to include. See LTV gain and loss produced by the backend in terms of gross revenue, gross profit, net revenue, or contribution margin.',
    'xKey': 'day',
    'xKeyAsRange': null,
    'allowSegmentQuickAdd': true,
    'allowCohorts': true,
    'splitSegmentFrontBack': true,
    'emptyDataRule': 'previous',
    'dateRangeType': 'dynamic',
    'dateRangeDynamicDays': 90,
    'dateRangeFixedStartDate': null,
    'dateRangeFixedEndDate': null,
    'resolution': 2,
    'segments': [],
    'config': {
      'attribution': 'customer',
      'frontendEnforcement': 'absolute',
      'includeCosts': true,
      'includeCommission': false,
      'extendBackend': true,
      'includeFrontend': true,
      'cumulative': true,
    },
    'configItems': {
      'attribution': {
        'show': false,
        'name': 'Attribution',
        'items': [
          {
            'value': 'customer',
            'name': 'Customer'
          },
          {
            'value': 'lead',
            'name': 'Lead'
          },
        ],
      },
      'frontendEnforcement': {
        'show': false,
        'name': 'Analyze LTV for',
        'items': [
          {
            'value': 'absolute',
            'name': 'New customers'
          },
          {
            'value': 'relative',
            'name': 'All customers'
          },
        ],
      },
      'includeCosts': {
        'show': false,
        'name': 'Include Costs',
        'items': [
          {
            'value': true,
            'name': 'Yes'
          },
          {
            'value': false,
            'name': 'No'
          },
        ],
      },
      'includeCommission': {
        'show': true,
        'name': 'Affiliate Commission',
        'items': [
          {
            'value': false,
            'name': 'Subtract from revenue'
          },
          {
            'value': true,
            'name': 'Add to revenue'
          },
        ],
      },
      'extendBackend': {
        'show': false,
        'name': 'Extend Backend',
        'items': [
          {
            'value': true,
            'name': 'To today'
          },
          {
            'value': false,
            'name': 'To end date'
          },
        ],
      },
      'includeFrontend': {
        'show': true,
        'name': 'LTV Days Include',
        'items': [
          {
            'value': true,
            'name': 'Full Journey'
          },
          {
            'value': false,
            'name': 'Backend Only'
          },
        ],
      },
      'cumulative': {
        'show': false,
        'name': 'Show LTV',
        'items': [
          {
            'value': true,
            'name': 'Cumulative'
          },
          {
            'value': false,
            'name': 'Incremental'
          },
        ],
      },
    },
    'metrics': {
      'defaultKey': 'ltv_nr_acc',
      'items': [
        {
          ...METRIC_ITEMS_MAP['ltv_nr_acc'],
          'name': 'Net Revenue',
          'secondary': {
            ...METRIC_ITEMS_MAP['confidence'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['ltv_cm_acc'],
          'name': 'Contribution Margin',
          'secondary': {
            ...METRIC_ITEMS_MAP['confidence'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['ltv_gr_acc'],
          'name': 'Gross Revenue',
          'secondary': {
            ...METRIC_ITEMS_MAP['confidence'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['ltv_gp_acc'],
          'name': 'Gross Profit',
          'secondary': {
            ...METRIC_ITEMS_MAP['confidence'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['ltv_rf_acc'],
          'name': 'Refunds',
          'secondary': {
            ...METRIC_ITEMS_MAP['confidence'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['count'],
          'secondary': {
            ...METRIC_ITEMS_MAP['confidence'],
          },
        },
      ],
    },
    'milestoneDays': [0, 7, 14, 30, 60, 90, 120, 150, 180, 270, 365],
    'chart': {
      'allowSecondaryChart': true,
      'allowLineDots': true,
      'allowUseStack100': true,
      'cumulativeDefault': false,
      'hideYValuesAfterToday': false,
    },
    'table': {
      'defaultTab': 'summary',
      'summary': {
        'show': true,
        'columns': {
          'default': {
            'name': 'Net Revenue',
            'description': 'Revenue minus refunds',
            'orderBy': 'customers_new',
            'order': 'desc',
            'items': [
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['customers_new'],
                'name': 'New Customers',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_frontend_gr'],
                'name': 'AOV',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_backend_gr'],
                'name': 'Backend Gain',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_backend_rf'],
                'name': 'Refund Loss',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_backend_nr'],
                'name': 'Backend Net',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_lifetime_nr'],
                'name': 'LTV Lifetime',
              },
              {
                'type': 'milestones',
                'metricKey': 'ltv_nr_acc',
                'name': 'LTV',
                'concatDay': true,
                'milestoneDays': [0, 30, 60, 90, 'max'],
              },
            ],
          },
          'ltv_cm': {
            'name': 'Contribution Margin',
            'description': 'Revenue minus refunds and costs',
            'orderBy': 'customers_new',
            'order': 'desc',
            'items': [
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['customers_new'],
                'name': 'New Customers',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_frontend_gp'],
                'name': 'AOV',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_backend_gp'],
                'name': 'Backend Gain',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_backend_rf'],
                'name': 'Refund Loss',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_backend_cm'],
                'name': 'Backend Net',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_lifetime_cm'],
                'name': 'LTV Lifetime',
              },
              {
                'type': 'milestones',
                'metricKey': 'ltv_cm_acc',
                'name': 'LTV',
                'concatDay': true,
                'milestoneDays': [0, 30, 60, 90, 'max'],
              },
            ],
          },
          'ltv_gr': {
            'name': 'Gross Revenue',
            'description': 'Total revenue',
            'orderBy': 'customers_new',
            'order': 'desc',
            'items': [
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['customers_new'],
                'name': 'New Customers',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_frontend_gr'],
                'name': 'AOV',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_backend_gr'],
                'name': 'Backend Gain',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_lifetime_gr'],
                'name': 'LTV Lifetime',
              },
              {
                'type': 'milestones',
                'metricKey': 'ltv_gr_acc',
                'name': 'LTV',
                'concatDay': true,
                'milestoneDays': [0, 30, 60, 90, 'max'],
              },
            ],
          },
          'ltv_gp': {
            'name': 'Gross Profit',
            'description': 'Revenue minus costs',
            'orderBy': 'customers_new',
            'order': 'desc',
            'items': [
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['customers_new'],
                'name': 'New Customers',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_frontend_gp'],
                'name': 'AOV',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_backend_gp'],
                'name': 'Backend Gain',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_lifetime_gp'],
                'name': 'LTV Lifetime',
              },
              {
                'type': 'milestones',
                'metricKey': 'ltv_gp_acc',
                'name': 'LTV',
                'concatDay': true,
                'milestoneDays': [0, 30, 60, 90, 'max'],
              },
            ],
          },
          'ltv_rf': {
            'name': 'Refunds',
            'description': 'Refunds only',
            'orderBy': 'customers_new',
            'order': 'desc',
            'items': [
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['customers_new'],
                'name': 'New Customers',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_frontend_gr'],
                'name': 'AOV',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_backend_rf'],
                'name': 'Refund Loss',
              },
              {
                'type': 'milestones',
                'metricKey': 'ltv_rf_acc',
                'name': 'LTV',
                'concatDay': true,
                'milestoneDays': [0, 30, 60, 90, 'max'],
              },
            ],
          },
          'ltv_blend': {
            'name': 'Overview',
            'description': 'LTV overview across all metrics',
            'orderBy': 'customers_new',
            'order': 'desc',
            'items': [
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['customers_new'],
                'name': 'New Customers',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_frontend_gr'],
                'name': 'AOV (Gross Revenue)',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_frontend_gp'],
                'name': 'AOV (Gross Profit)',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_backend_gr'],
                'name': 'Backend Gain (Gross Revenue)',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_backend_gp'],
                'name': 'Backend Gain (Gross Profit)',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_backend_rf'],
                'name': 'Refund Loss',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_backend_nr'],
                'name': 'Backend Net (Net Revenue)',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_backend_cm'],
                'name': 'Backend Net (Contribution Margin)',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_lifetime_gr'],
                'name': 'LTV Lifetime (Gross Revenue)',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_lifetime_gp'],
                'name': 'LTV Lifetime (Gross Profit)',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_lifetime_nr'],
                'name': 'LTV Lifetime (Net Revenue)',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_lifetime_cm'],
                'name': 'LTV Lifetime (Contribution Margin)',
              },
            ],
          },
        },
      },
      'chart': {
        'show': true,
      },
      'milestones': {
        'show': true,
      },
    },
  },

  {
    'kind': 'core',
    'key': 'lt_overview',
    'name': 'Lifetime Overview (Alpha)',
    'description': 'Comphrensive customer journey analysis that shows the frontend, backend, and overall customer journey, with the ability to filter both frontend and backend sales to include. See LTV gain and loss produced by the backend in terms of gross revenue, gross profit, net revenue, or contribution margin.',
    'xKey': 'day',
    'xKeyAsRange': null,
    'allowSegmentQuickAdd': true,
    'allowCohorts': true,
    'splitSegmentFrontBack': true,
    'emptyDataRule': 'previous',
    'dateRangeType': 'dynamic',
    'dateRangeDynamicDays': 90,
    'dateRangeFixedStartDate': null,
    'dateRangeFixedEndDate': null,
    'resolution': 2,
    'segments': [],
    'config': {
      'attribution': 'customer',
      'frontendEnforcement': 'absolute',
      'includeCosts': true,
      'includeCommission': false,
      'extendBackend': true,
      'includeFrontend': true,
      'cumulative': true,
    },
    'configItems': {
      'attribution': {
        'show': false,
        'name': 'Attribution',
        'items': [
          {
            'value': 'customer',
            'name': 'Customer'
          },
          {
            'value': 'lead',
            'name': 'Lead'
          },
        ],
      },
      'frontendEnforcement': {
        'show': false,
        'name': 'Analyze LTV for',
        'items': [
          {
            'value': 'absolute',
            'name': 'New customers'
          },
          {
            'value': 'relative',
            'name': 'All customers'
          },
        ],
      },
      'includeCosts': {
        'show': false,
        'name': 'Include Costs',
        'items': [
          {
            'value': true,
            'name': 'Yes'
          },
          {
            'value': false,
            'name': 'No'
          },
        ],
      },
      'includeCommission': {
        'show': true,
        'name': 'Affiliate Commission',
        'items': [
          {
            'value': false,
            'name': 'Subtract from revenue'
          },
          {
            'value': true,
            'name': 'Add to revenue'
          },
        ],
      },
      'extendBackend': {
        'show': false,
        'name': 'Extend Backend',
        'items': [
          {
            'value': true,
            'name': 'To today'
          },
          {
            'value': false,
            'name': 'To end date'
          },
        ],
      },
      'includeFrontend': {
        'show': true,
        'name': 'LTV Days Include',
        'items': [
          {
            'value': true,
            'name': 'Full Journey'
          },
          {
            'value': false,
            'name': 'Backend Only'
          },
        ],
      },
      'cumulative': {
        'show': false,
        'name': 'Show LTV',
        'items': [
          {
            'value': true,
            'name': 'Cumulative'
          },
          {
            'value': false,
            'name': 'Incremental'
          },
        ],
      },
    },
    'metrics': {
      'defaultKey': 'ltv_nr_acc',
      'items': [
        {
          ...METRIC_ITEMS_MAP['ltv_nr_acc'],
          'name': 'LTV Net Revenue',
          'secondary': {
            ...METRIC_ITEMS_MAP['confidence'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['ltv_cm_acc'],
          'name': 'LTV Contribution Margin',
          'secondary': {
            ...METRIC_ITEMS_MAP['confidence'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['ltv_gr_acc'],
          'name': 'LTV Gross Revenue',
          'secondary': {
            ...METRIC_ITEMS_MAP['confidence'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['ltv_gp_acc'],
          'name': 'LTV Gross Profit',
          'secondary': {
            ...METRIC_ITEMS_MAP['confidence'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['ltv_rf_acc'],
          'name': 'LTV Refunds',
          'secondary': {
            ...METRIC_ITEMS_MAP['confidence'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['ltnr_acc'],
          'name': 'Total Net Revenue',
        },
        {
          ...METRIC_ITEMS_MAP['ltcm_acc'],
          'name': 'Total Contribution Margin',
        },
        {
          ...METRIC_ITEMS_MAP['ltgr_acc'],
          'name': 'Total Gross Revenue',
        },
        {
          ...METRIC_ITEMS_MAP['ltgp_acc'],
          'name': 'Total Gross Profit',
        },
        {
          ...METRIC_ITEMS_MAP['ltrf_acc'],
          'name': 'Total Refunds',
        },
      ],
    },
    'milestoneDays': [0, 7, 14, 30, 60, 90, 120, 150, 180, 270, 365],
    'chart': {
      'allowSecondaryChart': true,
      'allowLineDots': true,
      'allowUseStack100': true,
      'cumulativeDefault': false,
      'hideYValuesAfterToday': false,
    },
    'table': {
      'defaultTab': 'summary',
      'summary': {
        'show': true,
        'columns': {
          'default': {
            'name': 'Lifetime Overview (Net Revenue)',
            'description': 'Lifetime Overview based on Net Revenue',
            'orderBy': 'customers_new',
            'order': 'desc',
            'items': [
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['customers_new'],
                'name': 'New Customers',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['frontend_gr'],
                'name': 'Gross Revenue (Frontend)',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_frontend_gr'],
                'name': 'AOV',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['backend_gr'],
                'name': 'Gross Revenue (Backend)',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['backend_rf'],
                'name': 'Refunds',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['backend_nr'],
                'name': 'Net Revenue (Backend)',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_backend_gr'],
                'name': 'Backend Gain',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_backend_rf'],
                'name': 'Refund Loss',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_backend_nr'],
                'name': 'Backend Net',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['lifetime_gr'],
                'name': 'Gross Revenue (Total)',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['lifetime_nr'],
                'name': 'Net Revenue (Total)',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_lifetime_nr'],
                'name': 'LTV',
              },
            ],
          },
          'lifetime_cm_overview': {
            'name': 'Lifetime Overview (Contribution Margin)',
            'description': 'Lifetime Overview based on Contribution Margin',
            'orderBy': 'customers_new',
            'order': 'desc',
            'items': [
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['customers_new'],
                'name': 'New Customers',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['frontend_gp'],
                'name': 'Gross Profit (Frontend)',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_frontend_gp'],
                'name': 'AOV',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['backend_gp'],
                'name': 'Gross Profit (Backend)',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['backend_rf'],
                'name': 'Refunds',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['backend_cm'],
                'name': 'Contribution Margin (Backend)',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_backend_gp'],
                'name': 'Backend Gain',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_backend_rf'],
                'name': 'Refund Loss',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_backend_cm'],
                'name': 'Backend Net',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['lifetime_gp'],
                'name': 'Gross Profit (Total)',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['lifetime_cm'],
                'name': 'Contribution Margin (Total)',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['ltv_lifetime_cm'],
                'name': 'LTV',
              },
            ],
          },
        },
      },
      'chart': {
        'show': true,
      },
      'milestones': {
        'show': true,
      },
    },
  },

  {
    'kind': 'core',
    'key': 'ret_1',
    'name': 'Retention 1 (Alpha)',
    'description': 'Comphrensive customer journey analysis that shows the frontend, backend, and overall customer journey, with the ability to filter both frontend and backend sales to include. See LTV gain and loss produced by the backend in terms of gross revenue, gross profit, net revenue, or contribution margin.',
    'xKey': 'day',
    'xKeyAsRange': 'key',
    'allowSegmentQuickAdd': true,
    'allowCohorts': true,
    'splitSegmentFrontBack': true,
    'emptyDataRule': 'previous',
    'dateRangeType': 'dynamic',
    'dateRangeDynamicDays': 180,
    'dateRangeFixedStartDate': null,
    'dateRangeFixedEndDate': null,
    'resolution': 5,
    'segments': [],
    'config': {
      'attribution': 'customer',
      'frontendEnforcement': 'absolute',
      'includeCosts': true,
      'includeCommission': false,
      'extendBackend': true,
      'includeFrontend': true,
      'cumulative': true,
    },
    'configItems': {
      'attribution': {
        'show': false,
        'name': 'Attribution',
        'items': [
          {
            'value': 'customer',
            'name': 'Customer'
          },
          {
            'value': 'lead',
            'name': 'Lead'
          },
        ],
      },
      'frontendEnforcement': {
        'show': false,
        'name': 'Analyze LTV for',
        'items': [
          {
            'value': 'absolute',
            'name': 'New customers'
          },
          {
            'value': 'relative',
            'name': 'All customers'
          },
        ],
      },
      'includeCosts': {
        'show': false,
        'name': 'Include Costs',
        'items': [
          {
            'value': true,
            'name': 'Yes'
          },
          {
            'value': false,
            'name': 'No'
          },
        ],
      },
      'includeCommission': {
        'show': true,
        'name': 'Affiliate Commission',
        'items': [
          {
            'value': false,
            'name': 'Subtract from revenue'
          },
          {
            'value': true,
            'name': 'Add to revenue'
          },
        ],
      },
      'extendBackend': {
        'show': false,
        'name': 'Extend Backend',
        'items': [
          {
            'value': true,
            'name': 'To today'
          },
          {
            'value': false,
            'name': 'To end date'
          },
        ],
      },
      'includeFrontend': {
        'show': true,
        'name': 'Days Include',
        'items': [
          {
            'value': true,
            'name': 'Full Journey'
          },
          {
            'value': false,
            'name': 'Backend Only'
          },
        ],
      },
      'cumulative': {
        'show': false,
        'name': 'Show LTV',
        'items': [
          {
            'value': true,
            'name': 'Cumulative'
          },
          {
            'value': false,
            'name': 'Incremental'
          },
        ],
      },
    },
    'metrics': {
      'defaultKey': 'orders_rtn',
      'items': [
        {
          ...METRIC_ITEMS_MAP['orders_rtn'],
          'name': 'Order Retention',
          'secondary': {
            ...METRIC_ITEMS_MAP['confidence'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['customers_all_rtn'],
          'name': 'Customer Retention (All)',
          'secondary': {
            ...METRIC_ITEMS_MAP['confidence'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['customers_orders_rtn'],
          'name': 'Customer Retention (Orders)',
          'secondary': {
            ...METRIC_ITEMS_MAP['confidence'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['customers_rebills_rtn'],
          'name': 'Customer Retention (Rebills)',
          'secondary': {
            ...METRIC_ITEMS_MAP['confidence'],
          },
        },
        {
          ...METRIC_ITEMS_MAP['orders'],
          'name': 'Orders',
        },
        {
          ...METRIC_ITEMS_MAP['customers_all'],
          'name': 'Customers (All)',
        },
        {
          ...METRIC_ITEMS_MAP['customers_orders'],
          'name': 'Customers (Orders)',
        },
        {
          ...METRIC_ITEMS_MAP['customers_rebills'],
          'name': 'Customers (Rebills)',
        },
      ],
    },
    'milestoneDays': [30, 60, 90, 120, 150, 180, 270, 365],
    'chart': {
      'allowSecondaryChart': true,
      'allowLineDots': true,
      'allowUseStack100': true,
      'cumulativeDefault': false,
      'hideYValuesAfterToday': false,
    },
    'table': {
      'defaultTab': 'summary',
      'summary': {
        'show': true,
        'columns': {
          'default': {
            'name': 'Order Retention',
            'description': 'Retention based only on orders',
            'orderBy': 'customers_new',
            'order': 'desc',
            'items': [
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['customers_new'],
                'name': 'Frontend Orders',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['backend_orders'],
                'name': 'Backend Orders',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['lifetime_orders'],
                'name': 'Lifetime Orders',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['backend_orders_rtn'],
                'name': 'Backend Orders Retention',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['lifetime_orders_rtn'],
                'name': 'Lifetime Orders Retention',
              },
              {
                'type': 'milestones',
                'metricKey': 'orders_rtn',
                'name': 'LTV',
                'concatDay': true,
                'milestoneDays': [30, 60, 90, 120, 150, 180, 270, 365],
              },
            ],
          },
          'customers_all': {
            'name': 'Customer Retention (All)',
            'description': 'Customer retention based on orders and rebills',
            'orderBy': 'customers_new',
            'order': 'desc',
            'items': [
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['customers_new'],
                'name': 'Frontend Orders',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['backend_customers_all'],
                'name': 'Backend Customers',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['lifetime_customers_all'],
                'name': 'Lifetime Customers',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['backend_customers_all_rtn'],
                'name': 'Backend Customer Retention',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['lifetime_customers_all_rtn'],
                'name': 'Lifetime Customer Retention',
              },
              {
                'type': 'milestones',
                'metricKey': 'customers_all_rtn',
                'name': 'LTV',
                'concatDay': true,
                'milestoneDays': [30, 60, 90, 120, 150, 180, 270, 365],
              },
            ],
          },
          'customers_orders': {
            'name': 'Customer Retention (Orders)',
            'description': 'Customer retention based customer orders only',
            'orderBy': 'customers_new',
            'order': 'desc',
            'items': [
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['customers_new'],
                'name': 'Frontend Orders',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['backend_customers_orders'],
                'name': 'Backend Customers',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['lifetime_customers_orders'],
                'name': 'Lifetime Customers',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['backend_customers_orders_rtn'],
                'name': 'Backend Customer Retention',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['lifetime_customers_orders_rtn'],
                'name': 'Lifetime Customer Retention',
              },
              {
                'type': 'milestones',
                'metricKey': 'customers_orders_rtn',
                'name': 'LTV',
                'concatDay': true,
                'milestoneDays': [30, 60, 90, 120, 150, 180, 270, 365],
              },
            ],
          },
          'customers_rebills': {
            'name': 'Customer Retention (Rebills)',
            'description': 'Customer retention based subscription rebills only',
            'orderBy': 'customers_new',
            'order': 'desc',
            'items': [
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['customers_new'],
                'name': 'Frontend Orders',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['backend_customers_rebills'],
                'name': 'Backend Customers',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['lifetime_customers_rebills'],
                'name': 'Lifetime Customers',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['backend_customers_rebills_rtn'],
                'name': 'Backend Customer Retention',
              },
              {
                'type': 'metric',
                ...METRIC_ITEMS_MAP['lifetime_customers_rebills_rtn'],
                'name': 'Lifetime Customer Retention',
              },
              {
                'type': 'milestones',
                'metricKey': 'customers_rebills_rtn',
                'name': 'LTV',
                'concatDay': true,
                'milestoneDays': [30, 60, 90, 120, 150, 180, 270, 365],
              },
            ],
          },
        },
      },
      'chart': {
        'show': true,
      },
      'milestones': {
        'show': true,
      },
    },
  },
]
